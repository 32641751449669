import React from "react";
import {Link} from "react-scroll";
import  'bootstrap/dist/css/bootstrap.min.css';
import dezyneLogo from "../components/logo-verum-dezyne.png";
import arrowNext from "../components/arrow-next-light-dark.png";
import dezyneLanguage from "../components/features/dezyne_language.png";
import codeGeneration from "../components/features/code_generation.png";
import dezyneIDE from "../components/features/dezyne_ide.png";
import verification from "../components/features/verification.png";
import rapidPrototype from "../components/features/rapid-prototyping-code-first.png"
import banner from '../components/banner-dezyne-new.jpeg';
import debug from "../components/features/debug.png";
import explore from "../components/features/explore.png";
import applicability from "../components/features/applicability.png"
import iconTestimonial from "../components/icon-testimonial.png";
import loadable from '@loadable/component'
import Footer  from "../components/Footer";
import Nav from "../components/Nav";
const DiscoverDezyneForm = loadable(() => import('../components/Forms/DiscoverDezyneForm'))
// const Footer = loadable(() => import('../components/Footer'));
// const Nav = loadable(() => import ('../components/Nav'));
// const TopNav = loadable(() => import ('../components/TopNav'))

function DiscoverDezyne() {
    
  return (
      <>
<Nav/>
<section className="wow animate__fadeIn cover-background background-position-top margin-80px-top top-space" style={{backgroundImage:`url(${banner})`,backgroundSize:100}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 d-flex flex-column justify-content-center text-center">
                    <h2 class="alt-font font-weight-700 text-white  mx-auto margin-15px-bottom sm-margin-10px-bottom letter-spacing-1 wow animate__fadeInDown">Verum Dezyne</h2>
                    <h5 class="alt-font font-weight-600 text-white mx-auto no-margin-bottom letter-spacing-1 font-italic wow animate__fadeInDown">Code first without downsides</h5>                    </div>
                </div>
                <div className="row justify-content-start bg-white align-items-start box-shadow-large border-radius-10 padding-10px-lr padding-25px-tb margin-100px-top sm-margin-50px-top wow animate__fadeInUp">                   
                    <div className="col-12 col-lg-6 col-md-6 col-sm-8 md-margin-30px-bottom wow animate__fadeInUp">
                        <h3 className="title-section alt-font text-dark-gray letter-spacing-1 margin-15px-bottom">Download <img src={dezyneLogo} alt="" width="150"/></h3>
                        <p className="w-95 sm-w-95">Join the community of software engineers and scientists who use Dezyne to develop flawless software for embedded and cyber-physical systems.</p>
                        <p className="w-95 sm-w-95">Trials are free and we don’t ask things like credit card details.</p>
                        <p className="w-95 sm-w-95">Follow this link to download and install Verum Dezyne on your desktop.</p>
                        <a href="/download"><img src={arrowNext} alt="" width="24"/></a>                    </div>
                    <div className="col-12 col-lg-6 col-md-6 col-sm-8 md-margin-30px-bottom wow animate__fadeInUp" data-wow-delay="0.2s">
                        <h3 className="title-section alt-font text-dark-gray letter-spacing-1">Verum Dezyne: a power language</h3>
                        <p className="w-95 sm-w-95"><strong>Verum Dezyne</strong> uses Dezyne, an open-source, plain text, domain specific language, to design the structure and behavior of a software system with a syntax similar to that of common programming languages such as Java or C. It allows to generate directly deployable code such as C, C++ and C#.</p>
                        <p className="w-95 sm-w-95">It makes hard things as formal methods very simple, in the basis it works the same way as a grammar checker in a word processor. Verum Dezyne creates confidence in the result of your work.</p>
                        <Link to="features-section" href=""><img src={arrowNext} alt="" width="24"/></Link></div>

                </div>
            </div>
        </section>
        <section className="wow animate__fadeIn bg-light-gray">
            <div className="container">
                <div className="row justify-content-start align-items-start">                   
                    <div className="col-12 col-lg-6 col-md-6 col-sm-8 md-margin-30px-bottom fit-videos wow animate__fadeInLeft">
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/DWIM78Pl4ss?autoplay=0;&mute=1;rel=0&amp;showinfo=0" allowfullscreen></iframe>
                    </div>
                    <div className="col-12 col-lg-6 col-md-6 col-sm-8 md-margin-30px-bottom wow animate__fadeInRight" data-wow-delay="0.2s">
                        <h3 className="title-section alt-font text-dark-gray letter-spacing-1">Verum Dezyne Quick Start</h3>
                      <p>Verum Dezyne comes as a convenient ready-to-use package, equipped with LSP and plenty of powerful GUI tools.  Verum provides rich documentation, tutorials and working examples. Dezyne is available in two editions:</p>
                        <ul className="list-style-1 list-style1-1 p-0 margin-15px-left">
                            <li>Visual Studio Code  Extension: Simply download the extension from the Marketplace, link the Dezyne package directory, create a .dzn file and access all Dezyne tools through the right-click context menu.</li>
                            <li>Command-line tool: It offers extensive configuration options, designed for complex project build and CI/CD  tool-chains</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section id="features-section" className="wow animate__fadeIn">
            <div  className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-5 col-lg-6 col-md-7 col-sm-8 margin-two-bottom md-margin-30px-bottom sm-margin-30px-bottom text-center">
                        <h3 className="title-section alt-font text-dark-gray letter-spacing-1">Features</h3>
                  </div>
                </div>
                <div className="row justify-content-start">
                    <div className="col-12 col-lg-6 col-md-6 col-sm-6 margin-50px-bottom md-margin-30px-bottom xs-margin-30px-bottom wow animate__fadeInUp last-paragraph-no-margin">
                        <h5 className="title-section-alt alt-font text-dark-gray letter-spacing-1">Dezyne Language</h5>
                        <img src={dezyneLanguage} />
                        
                        <p className="margin-25px-top">Dezyne is an open, C-like language for developing complex embedded software. It uses a "Design by Contract" approach, serving as an intermediary between formal models and target source code. Dezyne's building blocks are interfaces and components, enabling the creation of complex systems through composition. Its interactions are visualized as state diagrams, allowing for easier control flow analysis.</p>
                    </div>
                    <div className="col-12 col-lg-6 col-md-6 col-sm-6 margin-50px-bottom md-margin-30px-bottom xs-margin-30px-bottom wow animate__fadeInUp last-paragraph-no-margin" data-wow-delay="0.2s">
                        <h5 className="title-section-alt alt-font text-dark-gray letter-spacing-1">Dezyne IDE</h5>
                        <ul className="portfolio-gallery portfolio-wrapper grid grid-loading grid-1col xl-grid-1col lg-grid-1col md-grid-1col sm-grid-1col xs-grid-1col list-style-none pl-0 mb-0">
                            <li className="grid-sizer"></li>
                            <li className="grid-item wow animate__fadeInUp">
                                    <figure><div className="portfolio-img bg-extra-dark-gray"><img src={dezyneIDE}    alt="" className="project-img-gallery"/></div></figure>
                            </li>
                        </ul>
                        <p className="margin-25px-top">Verum Dezyne supports modern development environments such as VS Code through the Language Server Protocol. The VS Code extension for Dezyne also provides access to Dezyne commands such as “Code”, “Simulate” and “Verify” as well as interactive system, sequence, and state diagrams.</p>
                    </div>
                    <div className="col-12 col-lg-6 col-md-6 col-sm-8 margin-50px-bottom md-margin-30px-bottom xs-margin-30px-bottom wow animate__fadeInUp last-paragraph-no-margin">
                        <h5 className="title-section-alt alt-font text-dark-gray letter-spacing-1">Code Generation</h5>
                        <ul className="portfolio-gallery portfolio-wrapper grid grid-loading grid-1col xl-grid-1col lg-grid-1col md-grid-1col sm-grid-1col xs-grid-1col list-style-none pl-0 mb-0">
                            <li className="grid-sizer"></li>
                            <li className="grid-item wow animate__fadeInUp">
                                    <figure><div className="portfolio-img bg-extra-dark-gray"><img src={codeGeneration}   alt="" className="project-img-gallery"/></div></figure>
                            </li>
                        </ul>
                        <p className="margin-25px-top">Dezyne provides code generators for C++, C# and more. The generated code is verified, validated, and directly deployable. Verum warrants that the generated code is equivalent to the verified model and entirely free of defects.</p>
                    </div>
                    <div className="col-12 col-lg-6 col-md-6 col-sm-8 margin-50px-bottom md-margin-30px-bottom xs-margin-30px-bottom wow animate__fadeInUp last-paragraph-no-margin" data-wow-delay="0.2s">
                        <h5 className="title-section-alt alt-font text-dark-gray letter-spacing-1">Verification</h5>
                        <ul className="portfolio-gallery portfolio-wrapper grid grid-loading grid-1col xl-grid-1col lg-grid-1col md-grid-1col sm-grid-1col xs-grid-1col list-style-none pl-0 mb-0">
                            <li className="grid-sizer"></li>
                            <li className="grid-item wow animate__fadeInUp">
                                    <figure><div className="portfolio-img bg-extra-dark-gray"><img src={verification}   alt="" className="project-img-gallery"/></div></figure>
                            </li>
                        </ul>
                        <p className="margin-25px-top">Verum Dezyne uses model checking to identify bugs and design flaws such as race conditions, logic jams, unhandled exceptions/interrupts. Verification is treated as a standard tool like compilation. Dezyne can verify interfaces and components separately for fast issue resolution. Small changes can be checked in seconds, which streamlines software development and improves code quality.</p>
                    </div>
                    <div className="col-12 col-lg-6 col-md-6 col-sm-8 margin-50px-bottom md-margin-30px-bottom xs-margin-30px-bottom wow animate__fadeInUp last-paragraph-no-margin" data-wow-delay="0.2s">
                        <h5 className="title-section-alt alt-font text-dark-gray letter-spacing-1">Rapid Prototyping: Code First</h5>
                        <ul className="portfolio-gallery portfolio-wrapper grid grid-loading grid-1col xl-grid-1col lg-grid-1col md-grid-1col sm-grid-1col xs-grid-1col list-style-none pl-0 mb-0">
                            <li className="grid-sizer"></li>
                            <li className="grid-item wow animate__fadeInUp">
                                    <figure><div className="portfolio-img bg-extra-dark-gray"><img src={rapidPrototype}   alt="" className="project-img-gallery"/></div></figure>
                            </li>
                        </ul>
                        <p className="margin-25px-top">Dezyne encourages a <u>code first approach</u>, by supporting the start of development even when the requirements and specifications are not detailed in full yet. Single functionalities can be rapidly implemented, simulated and verified for potential pitfalls. A working prototype, as a separate, atomic component, can be easily incorporated into a larger system. Allowing short iterations, Dezyne supports a highly agile software development process.</p>
                    </div>
                    <div className="col-12 col-lg-6 col-md-6 col-sm-8 margin-50px-bottom md-margin-30px-bottom xs-margin-30px-bottom wow animate__fadeInUp last-paragraph-no-margin">
                        <h5 className="title-section-alt alt-font text-dark-gray letter-spacing-1">Bug Traceability</h5>
                        <ul className="portfolio-gallery portfolio-wrapper grid grid-loading grid-1col xl-grid-1col lg-grid-1col md-grid-1col sm-grid-1col xs-grid-1col list-style-none pl-0 mb-0">
                            <li className="grid-sizer"></li>
                            <li className="grid-item wow animate__fadeInUp">
                                    <figure><div className="portfolio-img bg-extra-dark-gray"><img src={debug}   alt="" className="project-img-gallery"/></div></figure>
                            </li>
                        </ul>
                        <p className="margin-25px-top">Verum Dezyne provides precise feedback when the verification detects bugs, including the issue location and steps to reproduce it. The sequence diagram offers a visual representation of the system's state and factors leading up to the error. Dezyne can reveal intricate fault-scenarios that are often hard to detect in complex systems.</p>
                    </div>
                    <div className="col-12 col-lg-6 col-md-6 col-sm-8 margin-50px-bottom md-margin-30px-bottom xs-margin-30px-bottom wow animate__fadeInUp last-paragraph-no-margin" data-wow-delay="0.2s">
                        <h5 className="title-section-alt alt-font text-dark-gray letter-spacing-1">Validation and Exploration</h5>
                        <ul className="portfolio-gallery portfolio-wrapper grid grid-loading grid-1col xl-grid-1col lg-grid-1col md-grid-1col sm-grid-1col xs-grid-1col list-style-none pl-0 mb-0">
                            <li className="grid-sizer"></li>
                            <li className="grid-item wow animate__fadeInUp">
                                    <figure><div className="portfolio-img bg-extra-dark-gray"><img src={explore}   alt="" className="project-img-gallery"/></div></figure>
                            </li>
                        </ul>
                        <p className="margin-25px-top">Verum Dezyne offers graphical tools to validate and test the structure of the system and its specification and behavior. The Sequence Diagram lets users simulate the system, inspect variables, events and responses. The State Diagram explores every possible system state and its contributing factors. Dezyne's batch mode is compatible with modern CI/CD toolchains, enabling seamless testing and validation.</p>
                    </div>

                    <div className="col-12 col-lg-6 col-md-6 col-sm-8 margin-50px-bottom md-margin-30px-bottom xs-margin-30px-bottom wow animate__fadeInUp last-paragraph-no-margin">
                        <h5 className="title-section-alt alt-font text-dark-gray letter-spacing-1">Applicability in the Toolchain</h5>
                        <ul className="portfolio-gallery portfolio-wrapper grid grid-loading grid-1col xl-grid-1col lg-grid-1col md-grid-1col sm-grid-1col xs-grid-1col list-style-none pl-0 mb-0">
                            <li className="grid-sizer"></li>
                            <li className="grid-item wow animate__fadeInUp">
                                    <figure><div className="portfolio-img bg-extra-dark-gray"><img src={applicability}   alt="" className="project-img-gallery"/></div></figure>
                            </li>
                        </ul>
                        <p className="margin-25px-top">Verum Dezyne provides formal verification for all aspects of a system, applicable in any project stage, ensuring compliance with standards, reliability and performance. It offers a reliable retrofit/ maintenance solution by translating legacy code, enhancing maintainability and transparency in component relations. Dezyne's safety and security features validate system models, making it ideal for meeting safety standards and tackling security breaches.</p>
                    </div>

                </div>
            </div>
        </section>
        <section className="wow animate__fadeIn bg-light-gray">
            <div className="container">
                <div className="row justify-content-start align-items-start">                   
                    <div className="col-12 col-lg-6 col-md-6 col-sm-8 md-margin-30px-bottom wow animate__fadeInLeft">
                        <h3 className="title-section alt-font text-dark-gray letter-spacing-1">Join our Community</h3>
                        <p className="w-95 sm-w-95 min-h-100px">Discuss, learn, find advice, give advice, and ask your questions: about Dezyne and the concepts supporting Dezyne. Our forum is a platform, freely accessible for everybody that is interested in the Verum products.</p>
                        <a href="https://forum.verum.com" target="_blank" className="btn btn-transparent-dark-gray btn-large sm-margin-two-all">Join Community</a>
                    </div>
                    <div className="col-12 col-lg-6 col-md-6 col-sm-8 md-margin-30px-bottom wow animate__fadeInRight" data-wow-delay="0.2s">
                        <h3 className="title-section alt-font text-dark-gray letter-spacing-1">Keep me informed</h3>
                        <DiscoverDezyneForm/>
                    </div>
                </div>
            </div>
        </section>
        <section className="wow animate__fadeIn">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-5 col-lg-6 col-md-7 col-sm-8 margin-two-bottom md-margin-30px-bottom sm-margin-30px-bottom text-center">
                        <h3 className="title-section alt-font text-dark-gray letter-spacing-1">What our users say</h3>
                  </div>
                </div>
                <div className="row row-cols-1 row-cols-md-3 row-cols-sm-2 justify-content-center">
                    <div className="col-12 col-lg-4 col-md-4 col-sm-12 text-center sm-margin-40px-bottom wow animate__fadeInRight last-paragraph-no-margin">
                        <img src={iconTestimonial}   className="rounded-circle margin-30px-bottom sm-margin-10px-bottom" alt=""/>
                        <p className="w-90 mx-auto md-w-100">Dezyne reduces the extremely complex matter of formal verification to the role of a colleague who sits next to you and shows you all the bugs in an understandable form.</p>
                    </div>
                    <div className="col-12 col-lg-4 col-md-4 col-sm-12 text-center sm-margin-40px-bottom wow animate__fadeInRight last-paragraph-no-margin" data-wow-delay="0.2s">
                        <img src={iconTestimonial} className="rounded-circle margin-30px-bottom sm-margin-10px-bottom" alt=""/>
                        <p className="w-90 mx-auto md-w-100">Dezyne really builds trust and gives me this nice feeling of self-confidence while coding.</p>
                    </div>
                    <div className="col-12 col-lg-4 col-md-4 col-sm-12 text-center wow animate__fadeInRight last-paragraph-no-margin" data-wow-delay="0.4s">
                        <img src={iconTestimonial} className="rounded-circle margin-30px-bottom sm-margin-10px-bottom" alt=""/>
                        <p className="w-90 mx-auto md-w-100">Even if the requirements/ specifications are not finally defined, I can still start with coding and when Dezyne stops complaining I'm getting code that is reliable and actually works.</p>
                    </div>
                </div>
            </div>
        </section>

<Footer/>   
</>    

  );
}

export default DiscoverDezyne;


